<template>
  <table class="table-block">
    <thead class="table-header">
      <td>
        Название параметра
      </td>
      <td>
        Значение параметра
      </td>
    </thead>
    <tbody>
      <tr v-for="(item, i) of options" :key="i" class="table-block-item">
        <td>{{ item.title }}</td>
        <td>
          <p>{{ item.description }}</p>
          <!-- <div v-if="page === 'NewItem'" @click="onDel(item)" class="close-icon">
          <img src="../resource/img/close-circle.svg" alt="" />
        </div> -->
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AttributesTable",
  props: {
    options: {
      type: Array
    },
    page: {
      type: String
    }
  },
  methods: {
    onDel(item) {
      this.$emit("delete-item", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.description {
  max-width: 90%;
}
.close-icon {
  width: 20px !important;
  height: 20px;
  cursor: pointer;
  justify-self: flex-end;
  border: 0 !important;
  .vertical-line {
    position: relative;
    top: -57%;
    width: 100%;
    height: 1px;
    background: #006b90;
    border: 2px solid #006b90;
    border-radius: 10px;
  }
  .horisontal-line {
    position: relative;
    left: 40%;
    width: 0px;
    height: 100%;
    background: #006b90;
    border: 2px solid #006b90;
  }
}
.table-block {
  margin: 0 auto;
  width: 85%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
  table-layout: fixed;
  tr {
    box-sizing: border-box;
    &:nth-child(even) {
      background: #e6f1f4;
    }
  }
  td {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 30px;
    border: 1px solid #daebf0;
  }
  .table-header {
    color: #1f1f1f;
    border-radius: 10px 0 0 10px;
    td {
      background: #e6f1f4;
    }
  }
  .table-block-item {
    border-top: 1px solid #daebf0;
    font-size: 14px;
    td {
      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }
  .colored {
    background: #f3f9fb;
  }
}
</style>
