<template>
  <TepmplateBlock
    mod-class
    content-class="mytechnique-wrap mt-5"
    title-page="Редактирование устройства"
  >
    <div class="scroll-container">
      <vuescroll>
        <div
          class="d-flex justify-content-center align-items-center mb-5 mt-5"
          v-if="isShowSpinner"
        >
          <b-spinner />
        </div>

        <div class="add-item" v-else>
          <form @submit.prevent="onSubmit">
            <div class="add-item__img-load-wrap">
              <label class="file-select">
                <div v-if="!src" class="select-button"></div>
                <span v-if="value">{{ value.name }}</span>
                <span v-if="!src"
                  >Загрузите фотографию устройства
                  <help-popover
                    message="Рекомендуется фотографировать аппарат, заводя его через мобильное приложение."
                  />
                </span>
                <img class="preview-image" v-else :src="src" alt="" />
                <input type="file" @change="handleFileChange" />
              </label>
            </div>

            <label>Тип устройства</label>
            <b-select class="form-control form-group" v-model="rootDevice.type">
              <option
                v-for="item in deviceType"
                :key="item.id"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </b-select>

            <div class="position-relative mb-3">
              <label class="form-group__label required-label">
                Модель
              </label>
              <b-input
                v-model="rootDevice.title"
                class="search-technique-input form-control"
                :debounce="300"
                autocomplete="off"
              />
            </div>

            <CustomInput
              v-model="rootDevice.serialNum"
              label="Серийный номер"
              :required="true"
              :is-need-validation="true"
            >
              <template #popover>
                <help-popover
                  message="Серийный номер является уникальным и должен быть заполнен максимально правильно."
                />
              </template>
            </CustomInput>

            <CustomInput
              v-model="rootDevice.specialMarks"
              label="Инвентарный номер"
            />

            <CustomInput
              v-model="rootDevice.stickerNum"
              label="Номер стикера"
            />

            <CustomInput v-model="wear" label="Пробег(ч/б)" />

            <CustomInput v-model="colorWear" label="Пробег(цвет)" />

            <CustomInput
              v-model="rootDevice.costPerPage"
              label="Стоимость печати страницы чб. (руб.коп)"
            />

            <CustomInput
              v-model="rootDevice.costPerPageColor"
              label="Стоимость печати страницы цвет. (руб.коп)"
            />

            <CustomInput
              v-model="rootDevice.expenses"
              label="Расходы на аппарат"
            />

            <div class="admin-panel">
              <b-row>
                <b-col class="form-group" :lg="12">
                  <label class="form-group__label">Компания</label>
                  <b-select
                    @change="searchRootOffices"
                    v-model="rootDevice.companyId"
                    class="form-control form-control-sm form-mytechnique__search"
                  >
                    <option
                      v-for="company in allCompanies"
                      :value="company.id"
                      :key="company.id"
                      >{{ company.brand }}
                    </option>
                  </b-select>
                </b-col>

                <b-col class="form-group" :lg="12">
                  <label class="form-group__label">Офис</label>
                  <b-select
                    v-model="rootDevice.officeId"
                    class="form-control form-control-sm form-mytechnique__search"
                  >
                    <option
                      v-for="office in allOffices"
                      :value="office.id"
                      :key="office.id"
                      >{{ office.name }}</option
                    >
                  </b-select>
                </b-col>
              </b-row>
            </div>

            <button
              :disabled="
                !rootDevice.companyId || !rootDevice.officeId || isDataSending
              "
              @click="checkAvailable"
              class="btn btn-second add-item__btn"
            >
              <div>
                <span v-if="!isDataSending">Сохранить</span>
                <b-spinner v-else small label="Loading..."></b-spinner>
              </div>
            </button>
          </form>
        </div>
      </vuescroll>
    </div>
    <div
      @click="onWrapperClick"
      v-if="showAttributes"
      class="attributes-modal__wrapper"
    >
      <div @click.prevent="onModalClick" class="attributes-modal">
        <img
          @click="showAttributes = false"
          :src="closeIcon"
          class="close-icon"
          alt=""
        />

        <div class="attributes-modal__header">
          <CustomInput v-model="attributeTitle" label="Название параметра" />
          <CustomInput v-model="attributeValue" label="Значение параметра" />
        </div>
        <button
          class="btn btn-second add-item__btn mb-3 w-50 d-block mx-auto"
          @click="onAddAttributeClick"
          title="Добавление характеристик устройства"
        >
          Добавить атрибут
        </button>
        <AttributesTable
          page="NewItem"
          @delete-item="deleteTableItem"
          v-if="tableData.length > 0"
          :options="tableData"
        />
      </div>
    </div>

    <template>
      <div>
        <b-modal hide-footer v-model="modalShow">
          <p>
            Вы указали значение счетчика меньше, чем последнее значение.
            Уверены, что хотите сохранить?
          </p>

          <button @click="sendDataInfo" class="btn btn-second add-item__btn">
            Подтвердить
          </button>

          <button
            @click="modalShow = false"
            class="btn btn-second add-item__btn"
          >
            Отмена
          </button>
        </b-modal>
      </div>
    </template>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import CustomInput from "../../components/Form/FormElement/Input";
import AttributesTable from "../../components/AttributesTable";
import RequestManager from "../../function/request/RequestManager";
import { mapActions, mapGetters } from "vuex";
import { GET_ALL_OFFICES, GET_ALL_TAGS } from "../../store/types";
import vuescroll from "vuescroll";
import HelpPopover from "../../components/HelpPopover";

import { CompanyApi } from "@/function/request/apiV2";
import { OfficeApi } from "@/function/request/apiV2";
import { DeviceApi } from "@/function/request/apiV2";
import { TechniqueApi } from "@/function/request/apiV2";
import { CounterApi } from "@/function/request/apiV2";

let officesList = undefined;

export default {
  data: function() {
    return {
      isLoading: false,
      technique: null,
      techniqueList: [],
      focusOnInput: false,
      isTechniqueLoading: false,
      tableData: [],
      closeIcon: require("../../resource/img/close.svg"),
      src: null,
      showAttributes: false,
      attributeTitle: "",
      attributeValue: "",
      autocomplete: false,
      geoTagText: "",
      color_attr: false,
      isShowSpinner: false,
      isDataSending: false,

      deviceType: [
        {
          value: "printer",
          name: "Принтер",
          id: 1
        },
        {
          value: "scanner",
          name: "Сканнер",
          id: 2
        },
        {
          value: "copier",
          name: "Копир",
          id: 3
        },
        {
          value: "mfp",
          name: "МФУ",
          id: 4
        }
      ],

      limit: 500,

      allCompanies: [],
      allOffices: [],
      rootDevice: [],
      modalShow: false,

      companyApi: new CompanyApi(),
      officeApi: new OfficeApi(),
      deviceApi: new DeviceApi(),
      techniqueApi: new TechniqueApi(),
      CounterApi: new CounterApi(),

      wear: 0,
      colorWear: 0,
      oldWear: 0,
      oldColorWear: 0,
      wearState: ["wear", "color"],
      mainCompanyInfo: undefined
    };
  },

  name: "EditTehniquePage",
  components: {
    HelpPopover,
    CustomInput,
    TepmplateBlock,
    AttributesTable,
    vuescroll
  },

  props: {
    value: File
  },
  computed: {
    ...mapGetters(["officeList", "tagsList"]),
    ...mapGetters("devices", ["devicesTypesList", "devicesStatusList"]),
    geoTags() {
      return this.tagsList.results;
    },
    getClass() {
      return this.autocomplete ? "active" : "";
    },
    getSelectedTechnique() {
      const deviceTitle = this.device?.title?.trim().toLowerCase();
      const selectedTechnique = this.technique?.title?.trim().toLowerCase();
      return deviceTitle === selectedTechnique;
    },
    checkRequiredFields() {
      const errors = [];

      const titleNotValid = this.device?.title?.length
        ? undefined
        : "Поле 'Модель' не заполнено";

      const serialNumNotValid = this.device?.serial_num?.length
        ? undefined
        : "Поле 'Серийный номер' не заполнено'";

      const typeDeviceNotValid = this.device?.type?.length
        ? undefined
        : "Не выбран тип устройства";

      const officeNotValid =
        this.device?.office !== 0 ? undefined : "Не выбран офис";

      errors.push(
        titleNotValid,
        serialNumNotValid,
        typeDeviceNotValid,
        officeNotValid
      );

      return errors.filter(el => el !== undefined);
    },
    autocompleteTags() {
      return this.geoTags.filter(
        elem =>
          elem.title.slice(0, this.geoTagText.length).toLowerCase() ===
          this.geoTagText.toLowerCase()
      );
    },
    officeOptions() {
      const options = [];
      if (this.officeList !== undefined) {
        this.officeList.forEach(office => {
          options.push({ text: office.office_name, value: office.id });
        });
      }
      return options;
    },
    deviceTypesOptions() {
      const options = [];
      for (let key in this.devicesTypesList) {
        options.push({ text: this.devicesTypesList[key], value: key });
      }
      return options;
    },
    deviceId() {
      return this.$route.params.id;
    }
  },
  watch: {
    tableData(value) {
      this.device.attributes = value;
    }
  },

  methods: {
    handleFileChange(e) {
      this.src = URL.createObjectURL(e.target.files[0]);
      //this.device.picture = e.target.files[0];
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: this.limit,
        crmManagerId: this.currentUser.id
      });
      this.allCompanies = items;
      this.allCompanies.unshift(this.mainCompanyInfo);
      await this.getRootOffices();
    },

    async getRootOffices() {
      let allCompaniesIds = this.allCompanies.map(comp => parseInt(comp.id));
      allCompaniesIds.unshift(parseInt(this.mainCompanyInfo.id));
      const { items } = await this.officeApi.api.listOffice({
        limit: this.limit,
        companyIds: [allCompaniesIds]
      });
      officesList = items;
      this.allOffices = officesList.filter(
        office => office.companyId === parseInt(this.rootDevice.companyId)
      );
      // this.rootDevice.officeId = undefined;
      // this.allOffices = this.$store.getters.officeList;
    },

    async searchRootOffices() {
      await this.getRootOffices();
    },

    async getRootDevice() {
      try {
        this.isLoading = true;
        this.rootDevice = await this.deviceApi.api.getDevice(this.deviceId);
      } catch (e) {
        throw Error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async getTechniqueCounters() {
      this.CounterApi.api
        .deviceCounters(this.$route.params.id, {
          codeNames: this.wearState
        })
        .then(res => {
          res.forEach(item => {
            if (item.codeName === "wear") {
              this.wear = item.value;
              this.oldWear = item.value;
            }
            if (item.codeName === "color") {
              this.colorWear = item.value;
              this.oldColorWear = item.value;
            }
          });
          /* this.wear = res[1].value;
          this.colorWear = res[0].value;
          this.oldWear = res[1].value;
          this.oldColorWear = res[0].value; */
        });
      console.log(this.wearState);
    },

    async editTechniqueWear() {
      const numWear = this.wear.toString().replace(/\D/g, "");
      await this.CounterApi.api.deviceCountersReset(this.$route.params.id, {
        codeName: "wear",
        value: Number(numWear)
      });
    },

    async editTechniqueWearColor() {
      const numColorWear = this.colorWear.toString().replace(/\D/g, "");
      await this.CounterApi.api.deviceCountersReset(this.$route.params.id, {
        codeName: "color",
        value: Number(numColorWear)
      });
    },
    ...mapActions({ GET_ALL_TAGS, GET_ALL_OFFICES }),
    hideTechList() {
      setTimeout(() => {
        this.focusOnInput = false;
      }, 200);
    },
    async getTechnique() {
      if (this.device.title?.length >= 2) {
        try {
          this.isTechniqueLoading = true;
          const searchParams = {
            title: this.device.title,
            limit: 50
          };
          const { items } = await RequestManager().techniqueApi.techniqueList(
            searchParams
          );
          this.techniqueList = items?.length ? items : [];
        } finally {
          this.isTechniqueLoading = false;
        }
      }
    },
    setTechnique(technique) {
      this.focusOnInput = false;
      this.device.title = technique.title;
      this.technique = technique;
      this.device.technique_id = technique?.id ?? undefined;
      this.getTechnique();
    },
    deleteTableItem(item) {
      this.tableData = this.tableData.filter(elem => elem !== item);
    },
    deleteTag(item) {
      this.device.tags = this.device.tags.filter(elem => elem !== item);
    },
    toggleModal(value) {
      this.showAttributes = value;
    },
    onWrapperClick() {
      this.toggleModal(false);
    },
    onModalClick(e) {
      e.stopPropagation();
    },
    onAddAttributeClick() {
      if (this.attributeTitle.length > 0 && this.attributeValue.length > 0) {
        this.tableData = [
          ...this.tableData,
          { title: this.attributeTitle, description: this.attributeValue }
        ];
        this.attributeTitle = "";
        this.attributeValue = "";
      }
    },

    checkAvailable() {
      if (this.oldWear > this.wear || this.oldColorWear > this.colorWear) {
        this.modalShow = true;
      } else {
        this.sendDataInfo();
      }
    },

    async sendDataInfo() {
      let body = {
        techniqueId: this.rootDevice.techniqueId,
        officeId: String(this.rootDevice.officeId),
        title: this.rootDevice.title,
        type: this.rootDevice.type,
        status: "working",
        serialNum: this.rootDevice.serialNum,
        costPerPage: parseFloat(this.rootDevice.costPerPage),
        costPerPageColor: parseFloat(this.rootDevice.costPerPageColor),
        expenses: parseInt(this.rootDevice.expenses),
        tags: [],
        specialMarks: this.rootDevice.specialMarks,
        stickerNum: this.rootDevice.stickerNum,
        picturePath: this.rootDevice.picturePath
      };

      try {
        this.isDataSending = true;
        if (this.file) {
          console.log(this.file);
          const response = await RequestManager().filesApi.upload(
            "/",
            this.file
          );
          console.log(response);

          let a = true;
          if (a) {
            body.picturePath = response[0].path;
          }
        }

        // this.isShowSpinner = true;
        await this.deviceApi.api.editDevice(this.rootDevice.id, {
          body
        });

        await this.$bvToast.toast("Успешно сохранено", {
          variant: "success",
          title: "Успешно"
        });

        if (
          this.wear !== this.oldWear ||
          this.colorWear !== this.oldColorWear
        ) {
          await this.editTechniqueWear();
          await this.editTechniqueWearColor();
        }

        if (
          this.wear !== this.oldWear ||
          this.colorWear !== this.oldColorWear
        ) {
          await this.$bvToast.toast("Успешно сохранено", {
            variant: "success",
            title: "Успешно"
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isShowSpinner = false;

        setTimeout(() => {
          this.isDataSending = false;
          this.$router.go(-1);
        }, 800);
      }
    },

    onSubmit() {
      // const response = RequestManager().editDevice(this.deviceId, form);
      //
      // if (response?.success === false) {
      //   this.$bvToast.toast("Произошла непредвиденная ошибка", {
      //     variant: "danger",
      //     title: "Ошибка"
      //   });
      // }
      // if (this.checkRequiredFields?.length === 0) {
      //   const tags = [];
      //   this.device.tags.forEach(tag => {
      //     const item = this.geoTags.find(elem => elem.title === tag);
      //     tags.push(item.id);
      //   });
      //
      //   if (this.device.type === "1") {
      //     this.device.attributes = [
      //       ...this.device.attributes,
      //       { title: "Цвет", description: this.color_attr ? "Да" : "Нет" }
      //     ];
      //   }
      //
      //   this.device.technique_id = this.getSelectedTechnique
      //     ? this.technique?.id
      //     : null;
      //
      //   const formData = new FormData();
      //   for (let key in this.device) {
      //     if (key === "tags") {
      //       this.device[key].forEach(elem => {
      //         formData.append(
      //           "tag_ids",
      //           this.geoTags.find(item => item.title === elem).id
      //         );
      //       });
      //     } else if (key === "attributes") {
      //       formData.append(key, JSON.stringify(this.device.attributes));
      //     } else if (key === "office") {
      //       formData.append("office_id", this.device[key]);
      //     } else if (key === "technique_id") {
      //       if (this.device.technique_id) {
      //         formData.append("technique_id", this.device[key]);
      //       }
      //     } else {
      //       formData.append(key, this.device[key]);
      //     }
      //   }
      //   this.isShowSpinner = true;
      //   RequestManager()
      //     .addNewDevice(formData)
      //     .then(res => {
      //       if (res?.success === false) {
      //         const errors = res.logError?.response?.data;
      //         const allError = JSON.stringify(errors);
      //         this.$bvToast.toast(allError, {
      //           title: "Error",
      //           variant: "danger"
      //         });
      //       } else {
      //         this.$router.push({ name: "MyTechnique" });
      //       }
      //     })
      //     .catch(() => (this.isShowSpinner = false))
      //     .finally(() => (this.isShowSpinner = false));
      // } else {
      //   const message = this.checkRequiredFields?.join(", ");
      //   this.$bvToast.toast(message, {
      //     variant: "danger",
      //     title: "Ошибка"
      //   });
      // }
    },
    addGeoTag() {
      const exists = this.geoTags.some(elem => elem.title === this.geoTagText);
      if (exists) {
        this.device.tags = [...this.device.tags, this.geoTagText];
        this.geoTagText = "";
      } else {
        if (this.geoTagText.length > 0) {
          const data = {
            title: this.geoTagText
          };
          RequestManager()
            .createTag(data)
            .then(() => {
              this.GET_ALL_TAGS();
              this.device.tags = [...this.device.tags, this.geoTagText];
              this.geoTagText = "";
            });
        }
      }
    },

    fetchData() {
      const optionsArray = [];
      for (let key in this.devicesTypesList.devicesTypes) {
        optionsArray.push({
          text: this.devicesTypesList.devicesTypes[key],
          value: key
        });
      }
      this.options = optionsArray;
    }
  },

  created() {
    this.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    if (this.currentUser) {
      this.getRootDevice();
    }
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
  },

  /*  created() {
    this.getRootCompanies();
    if (this.currentUser.company) {
      this.getRootCompaniesByParams();
    }

    this.companyId = this.currentUser.company;
    
    this.searchRootOffices();
    console.log("this.allOffices",this.allOffices );
  //  this.officeId =  String(this.allOffices[0].id) ;

  },*/

  async mounted() {
    console.log("this.$route.params.id", this.$route.params.id);

    await this.getRootCompanies();

    await this.getTechniqueCounters();

    setTimeout(() => {
      this.rootDevice.wear = this.wear;
      this.rootDevice.colorWear = this.colorWear;
    }, 2000);
  }
};
</script>

<style lang="scss" scoped>
.search-technique-input {
  position: relative;
}

.searched-tech {
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px #80b5c8ff solid;
  padding: 10px 0;
  border-radius: 4px;
  max-height: 250px;
  overflow: auto;

  &__item {
    padding: 5px 10px;
    font-size: 14px;
    &:hover {
      background-color: #80b5c8ff;
      color: white;
    }
  }
}

.close-icon {
  filter: var(--filter-for-svg);
  width: 20px;
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: 5px;
  // top: -2px;
}
.scroll-container {
  width: 500px !important;
  margin: 0 auto;
  overflow: hidden;
  // max-height: 605px;
}
.attributes-modal__wrapper {
  top: 0;
  left: 0;
  //background: transparent;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100vh;

  .attributes-modal {
    position: absolute;
    width: 70%;
    padding: 20px;
    min-height: 100px;
    background: #ffffff;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
    border: 3px solid #cecece33;

    border-radius: 10px;
    top: 50%;
    left: 49.5%;
    transform: translate(-50%, -50%);
    &__header {
      display: flex;
      justify-content: space-between;
      div {
        flex-basis: 49%;
      }
    }
  }
}
.autocomplete {
  position: absolute;
  top: 84px;
  width: 100%;
  border: 1px solid rgba(206, 206, 206, 0.2);
  border-radius: 0 0 10px 10px;
  background: #f5f5f5;
  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
  .autocomplete-item {
    padding: 3px 10px;
  }
  .autocomplete-item:hover {
    background: #495057;
    color: white;
  }
}

.add-item {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  min-height: 672px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}

.add-item__textarea {
  background: rgba(206, 206, 206, 0.2);
  padding: 20px 15px;
  margin-bottom: 24px;

  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

  &::placeholder {
    color: #cfcfcf;
  }
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--second-main-color) !important;
}

.add-item__img-load-wrap {
  margin-bottom: 30px;
}

.add-item__btn {
  max-width: 100%;
}

.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 14px;

  color: var(--second-main-color);
}

.file-select > .select-button {
  background-image: url("../../resource/img/image-load.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 95px;
  height: 85px;
  cursor: pointer;
  margin-right: 17px;
}
.preview-image {
  // width: 95px;
  display: block;
  margin: 0 auto;
  max-height: 85px;
  cursor: pointer;
}

.file-select > input[type="file"] {
  display: none;
}

.geotag-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &__btn {
    position: absolute;
    top: 38px;
    right: -50px;
    display: flex;
    justify-content: center;
    width: 36px;
    height: 34px;
    align-items: center;
    border: 2px solid #016b90;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    &__text {
      color: #016b90;
      font-size: 50px;
    }
  }
}

.form-group__label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #1f1f1f;
  position: relative;
}

.required-label:after {
  content: "*";
  font-size: 26px;
  color: red;
  position: absolute;
}

.form-control {
  background: rgba(206, 206, 206, 0.2);
  border: 1px solid rgba(206, 206, 206, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 56px;
}

.form-control.invalid {
  border: 1px rgb(245, 0, 0) solid;
}

.form-control.invalid:focus {
  box-shadow: 0 0 0 3px rgba(245, 0, 0, 0.2);
}

.form-control.valid {
  border: 1px rgb(40, 167, 69) solid;
}

.form-control.valid:focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.2);
}

.geotags-items {
  display: flex;
  position: relative;
  left: -5px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  &__item {
    margin-bottom: 10px;
    border: 2px solid #e6f1f4;
    font-size: 12px;
    background: #e6f1f4;
    padding: 5px 10px;
    border-radius: 10px;
    margin-left: 5px;
  }
}

.table {
  color: var(--second-main-color);
}
</style>
